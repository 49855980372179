






























import Vue from "vue";
import { mapGetters, mapState } from "vuex";
export default Vue.extend({
  name: "InvoiceSelectSalesName",
  props: {
    value: {
      type: String,
      default: undefined,
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      form: (st: any) => st.invoiceStore.form
    }),
    ...mapGetters({
      listSales: "invoiceStore/GET_LIST_SALES"
    }),
  },
  methods: {
    onSelect(e: string): void {
      this.$emit("input", e);
      this.$emit("on-select", { value: e, meta: null });
    },
    clear(): void {
      this.$emit("input", undefined);
      this.$emit("on-select", { value: undefined, meta: null });
    },
  },
});
